<template>
  <div id="answer">
    <div class="camera_outer">
      <video
        id="videoCamera"
        :width="videoWidth"
        :height="videoHeight"
        autoplay
      ></video>

      <canvas
        style="display: none"
        id="canvasCamera"
        :width="videoWidth"
        :height="videoHeight"
      ></canvas>
      <div>
        <!-- <button @click="setImage">考试开始</button> -->
        <!-- <button @click="getall">考试开始</button> -->
      </div>
    </div>

    <el-container>
      <el-aside width="300px">
        <el-card class="box-card" shadow="hover">
          <time-count-down
            :totalTime="getExam.total_time"
            @hand-in="handIn"
          ></time-count-down>
          <el-divider content-position="center" v-if="choices.length>0">选择题</el-divider>
          <el-row class="question-tag" type="flex" justify=" space-around ">
            <el-col :span="24">
              <el-tag
                v-for="(item, index) in choices"
                :key="item.id"
                :type="index + 1 == current ? 'success' : ''"
                :effect="
                  index + 1 == current || answer[0][index] != null
                    ? 'dark'
                    : 'plain'
                "
                @click="changeQuestion(index + 1)"
              >
                {{ index + 1 }}
              </el-tag>
            </el-col>
          </el-row>

          <el-divider content-position="center" v-if="mulchoices.length>0">多选择题</el-divider>
          <el-row class="question-tag" type="flex" justify=" space-around ">
            <el-col :span="24">
              <el-tag
                v-for="(item, index) in mulchoices"
                :key="item.id"
                :type="index + mulchoicePos == current ? 'success' : ''"
                :effect="
                  index + mulchoicePos == current ||
                  mulchoice_answer[index][0] != null
                    ? 'dark'
                    : 'plain'
                "
                @click="changeQuestion(index + mulchoicePos)"
              >
                {{ index + mulchoicePos }}
              </el-tag>
            </el-col>
          </el-row>

          <el-divider content-position="center" v-if="fills.length>0">填空题</el-divider>
          <el-row class="question-tag">
            <el-col :span="24">
              <el-tag
                v-for="(item, index) in fills"
                :key="item.id"
                :type="index + fillPos == current ? 'success' : ''"
                :effect="
                  index + fillPos == current || answer[1][index] != null
                    ? 'dark'
                    : 'plain'
                "
                @click="changeQuestion(index + fillPos)"
              >
                {{ index + fillPos }}
              </el-tag>
            </el-col>
          </el-row>
          <el-divider content-position="center" v-if="judges.length>0">判断题</el-divider>
          <el-row class="question-tag">
            <el-col :span="24">
              <el-tag
                v-for="(item, index) in judges"
                :key="item.id"
                :type="index + judgePos == current ? 'success' : ''"
                :effect="
                  index + judgePos == current || answer[2][index] != null
                    ? 'dark'
                    : 'plain'
                "
                @click="changeQuestion(index + judgePos)"
              >
                {{ index + judgePos }}
              </el-tag>
            </el-col>
          </el-row>
          <el-divider content-position="center" v-if="programs.length>0">简答题</el-divider>
          <el-row class="question-tag">
            <el-col :span="24">
              <el-tag
                v-for="(item, index) in programs"
                :key="item.id"
                :type="index + programPos == current ? 'success' : ''"
                :effect="
                  index + programPos == current || answer[3][index] != null
                    ? 'dark'
                    : 'plain'
                "
                @click="changeQuestion(index + programPos)"
              >
                {{ index + programPos }}
              </el-tag>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <div class="bottom clearfix">
            <!-- <div class="bottomText"> -->
            <el-tag type="primary" effect="dark">已答</el-tag>
            <el-tag type="primary" effect="plain">未答</el-tag>
            <el-tag type="success" effect="dark">当前</el-tag>

            <br />
            <br />
            <el-button type="primary" class="button" @click="handIn"
              >交卷</el-button
            >
          </div>
        </el-card>
      </el-aside>
      <el-container>
        <el-header>
          <el-card class="box-card" shadow="hover" style="width: auto">
            <div id="information">
              <el-row type="flex" justify="center" style=" display: flex;
    align-items:center;">
                <el-col :span="4">
                  <span>学号：{{ getUser.username }}</span>
                </el-col>
                <el-col :span="4">
                  <span>姓名：{{ getStudent.name }}</span>
                </el-col>
                <el-col :span="4">
                  <span
                    >性别：{{ (getStudent.gender = "M" ? "男" : "女") }}</span
                  >
                </el-col>
                <el-col :span="4">
                  <span>专业:{{ clazz.major }}</span>
                  <!-- |{{ clazz.clazz }} 班级 -->
                </el-col>
                <el-col :span="6" style="color: red; font-weight: bold;text-align:left;border:1px solid #F00; ">
                  <!-- border:1px solid #F00; width:100px -->
                  <!-- 年级：{{ clazz.year }}  -->
                  <!-- <span> 考试注意事项</span> -->
                  <div>(1) 考试过程不要刷新</div>
                  <div>(2) 请诚信考试,全程录像</div>
                  <span></span>
                </el-col>
                <el-col :span="4">
                  <span> </span>
                  <!-- <span>班级：{{ clazz.clazz }}</span> -->
                </el-col>
              </el-row>
            </div>
          </el-card>
        </el-header>
        <el-main>
          <el-card
            class="box-card"
            shadow="hover"
            style="width: 800px; height: 500px; margin-top: 20px"
          >
            <!-- style="width: 1110px; height: 570px; margin-top: 20px" -->
            <div
              id="choice"
              v-for="(item, index) in choices"
              :key="index + 'index_0'"
              v-show="index + 1 == current"
            >
              <h1>{{ index + 1 }}. {{ item.question }} [{{ item.score }}分]</h1>
              <el-radio-group v-model="answer[0][index]">
                <el-radio label="A" border>A. {{ item.answer_A }}</el-radio
                ><br />
                <el-radio label="B" border>B. {{ item.answer_B }}</el-radio
                ><br />
                <el-radio label="C" border>C. {{ item.answer_C }}</el-radio
                ><br />
                <el-radio label="D" border>D. {{ item.answer_D }}</el-radio
                ><br />
              </el-radio-group>
            </div>

            <div
              id="mulchoice"
              v-for="(item, index) in mulchoices"
              :key="index + 'index_1'"
              v-show="index + mulchoicePos == current"
            >
              <!-- v-show="index + mulchoicePos == current" -->
              <h1>
                {{ index + mulchoicePos }}. {{ item.question }} [{{
                  item.score
                }}分]
              </h1>

              <!-- <el-checkbox-group v-model="answer[1]">
                <el-checkbox label="复选框 A">C.</el-checkbox>
                <br />
                <el-checkbox label="复选框 B">C. </el-checkbox>
                <br />
                <el-checkbox label="复选框 C">C. </el-checkbox>
              </el-checkbox-group> -->

              <!-- <el-checkbox-group v-model="chmulchoice_answer" size="small">
                <el-checkbox label="备选项1" border></el-checkbox>
                <el-checkbox label="备选项2" border disabled></el-checkbox>
              </el-checkbox-group> -->

              <el-checkbox-group
                v-model="mulchoice_answer[index]"
                size="medium"
              >
                <el-checkbox label="A" border
                  >A. {{ item.answer_A }}</el-checkbox
                >
                <br />
                <el-checkbox label="B" border
                  >B. {{ item.answer_B }}</el-checkbox
                >
                <br />
                <el-checkbox label="C" border
                  >C. {{ item.answer_C }}</el-checkbox
                >
                <br />
                <el-checkbox label="D" border
                  >D. {{ item.answer_D }}</el-checkbox
                >
                <br />
                <el-checkbox label="E" border v-if=" item.answer_E "
                  >E. {{ item.answer_E }}</el-checkbox
                >
                <br />
              </el-checkbox-group>
            </div>

            <div
              id="fill"
              v-for="(item, index) in fills"
              :key="index + 'index_3'"
              v-show="index + fillPos == current"
            >
              <h1>
                {{ index + fillPos }}. {{ item.question }} [{{ item.score }}分]
              </h1>
              <el-col :span="20">
                <el-input v-model="answer[1][index]"></el-input>
              </el-col>
            </div>

            <div
              id="judge"
              v-for="(item, index) in judges"
              :key="index + 'index_4'"
              v-show="index + judgePos == current"
            >
              <h1>
                {{ index + judgePos }}. {{ item.question }} [{{ item.socre }}分]
              </h1>
              <el-radio-group v-model="answer[2][index]">
                <el-radio label="T" border>正确</el-radio><br />
                <el-radio label="F" border>错误</el-radio><br />
              </el-radio-group>
            </div>

            <div
              id="program"
              v-for="(item, index) in programs"
              :key="index + 'index_5'"
              v-show="index + programPos == current"
            >
              <h1 style=" text-align: justify !important;">
                {{ index + programPos }}. {{ item.question }} [{{
                  item.score
                }}分]
              </h1>
              <el-row type="flex">
                <!-- <el-row type="flex" justify="space-around"> -->
                <el-col :span="20">
                  <!-- 答题区 -->
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 15, maxRows: 18 }"
                    v-model="answer[3][index]"
                  >
                  </el-input>
                </el-col>
              </el-row>
            </div>

            <!-- <el-col :span="4">
                  <el-button
                    type="success"
                    @click="checkProgram(index)"
                    style="margin-top: 20px"
                    >测试</el-button
                  >
                </el-col> -->
            <!-- <el-col :span="10">
           
                  输出信息
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 18, maxRows: 18 }"
                    v-model="testMsg"
                    disabled
                  >
                  </el-input>
                </el-col> -->
          </el-card>
        </el-main>
        <el-footer>
          <div class="box-div">
            <el-row type="flex" justify="start">
              <el-col :span="4" :offset="6">
                <el-button type="primary" @click="lastQuestion"
                  >上一题1</el-button
                >
              </el-col>
              <el-col :span="4">
                <el-button type="primary" @click="nextQuestion"
                  >下一题</el-button
                >
              </el-col>
            </el-row>
          </div>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
/* eslint-disable */
import TimeCountDown from "@/components/TimeCountDown.vue";
export default {
  data() {
    return {
      clazz: {},

      mulchoices: [],
      choices: [],
      fills: [],
      judges: [],
      programs: [],

      answer: [[], [], [], []], //保存已作答的答案

      mulchoice_answer: [[], [], [], []],

      checkboxGroup1: [],

      current: 1, //当前题号
      totalScore: 0, //总分
      testMsg: "", //编程题测试信息
      mulchoicesList: "",

      videoWidth: 130,
      videoHeight: 130,
      imgSrc: "",
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      validTip: "验证中",
      global_name: "",
      value: {},
    };
  },
  components: {
    TimeCountDown,
  },
  computed: {
    //用计算属性显示结果

    mulchoicePos() {
      return this.choices.length + 1;
    },

    fillPos() {
      return this.choices.length + this.mulchoices.length + 1;
    },

    judgePos() {
      return (
        this.choices.length + this.mulchoices.length + this.fills.length + 1
      );
    },
    programPos() {
      return (
        this.choices.length +
        this.mulchoices.length +
        this.fills.length +
        this.judges.length +
        1
      );
    },
    totalNum() {
      return (
        this.choices.length +
        this.fills.length +
        this.judges.length +
        this.programs.length +
        this.mulchoices.length
      );
    },
    getStudent() {
      return this.$store.state.student;
    },
    getUser() {
      return this.$store.state.user;
    },
    getPaper() {
      return localStorage.getItem("paper")
        ? JSON.parse(localStorage.getItem("paper"))
        : {};
    },
    getExam() {
      return localStorage.getItem("exam")
        ? JSON.parse(localStorage.getItem("exam"))
        : {};
    },
    getPractice() {
      return localStorage.getItem("practice")
        ? JSON.parse(localStorage.getItem("practice"))
        : {};
    },
    isPractice() {
      return this.$store.state.isPractice;
    },
  },
  methods: {
    // 获取班级信息
    getClazzInfo() {
      this.$axios(`/clazzs/${this.getStudent.clazz}/?format=json`)
        .then((res) => {
          console.log(res.data);
          this.clazz = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取选择题
    getChoiceInfo() {
      this.$axios(`/choices/?format=json`, {
        params: {
          choice_number: this.getPaper.choice_number,
          level: this.getPaper.level,
        },
      })
        .then((res) => {
          this.choices = res.data;
          console.log("choices", this.choices);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取多选择题
    getMulChoiceInfo() {
      this.$axios(`/mulchoices/?format=json`, {
        params: {
          multi_number: this.getPaper.mulchoice_number,
          level: this.getPaper.level,
        },
      })
        .then((res) => {
          this.mulchoices = res.data;
          console.log("MulChoiceInfo", this.mulchoices);
          // this.mulchoice_answer=[]
          // this.checkboxGroup1 = [];
          var arr = new Array();
          // this.mulchoices.length
          for (var i = 0; i < this.mulchoices.length; i++) {
            // this.checkboxGroup1.push([])
            arr.unshift([]);
          }
          // this.checkboxGroup1 = arr;

          this.mulchoice_answer = arr;

          // console.log("arr", this.checkboxGroup1,arr);

          // var arr = new Array(3);
          // arr.unshift([]);
          // console.log("arr", arr);

          // console.log(("MulChoiceInfo",this.mulchoices)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取填空题
    getFillInfo() {
      this.$axios("/fills/?format=json", {
        params: {
          fill_number: this.getPaper.fill_number,
          level: this.getPaper.level,
        },
      })
        .then((res) => {
          this.fills = res.data;
          console.log("FillInfo", this.fills);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取判断题
    getJudgeInfo() {
      this.$axios(`/judges/?format=json`, {
        params: {
          judge_number: this.getPaper.judge_number,
          level: this.getPaper.level,
        },
      })
        .then((res) => {
          this.judges = res.data;
          console.log("JudgeInfo", this.judges);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取编程题
    getProgramInfo() {
      this.$axios(`/programs/?format=json`, {
        params: {
          program_number: this.getPaper.program_number,
          level: this.getPaper.level,
        },
      })
        .then((res) => {
          this.programs = res.data;
          console.log("programs", this.programs);
          //如果是编程题，第一次加载答题模板
          this.loadProgram();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 下一题
    nextQuestion() {
      console.log(this.current, this.totalNum);
      if (this.current == this.totalNum) {
        this.$message("已经是最后一题了！");
      } else {
        this.current += 1;
      }
      //如果是编程题，第一次加载答题模板
      this.loadProgram();
    },
    // 上一题
    lastQuestion() {
      if (this.current == 1) {
        this.$message("已经是第一题了！");
      } else {
        this.current -= 1;
      }

      //如果是编程题，第一次加载答题模板
      this.loadProgram();
    },
    // 点击切换题目
    changeQuestion(number) {
      this.current = number;
      //如果是编程题，第一次加载答题模板
      this.loadProgram();
      console.log(
        this.answer, //保存已作答的答案
        this.mulchoice_answer
      );

      // this.goOverMulChoice()
    },
    // 第一次加载编程题答题模板
    loadProgram() {
      // let index = this.current - parseInt(this.programPos);
      // if (index >= 0 && this.answer[3][index] == null) {
      //   this.answer[3][index] = this.programs[index].answer_template;
      // }
      // // 清空输出信息
      // this.testMsg = null;
    },
    // 测试编程题
    checkProgram(index) {
      const msg = this;
      axios
        .post(`/check-program/`, {
          answer:
            this.answer[3][index] == undefined ? "" : this.answer[3][index],
          program: this.programs[index],
        })
        .then((res) => {
          //处理成功的函数 相当于success
          if (res) {
            if (res.data.message == "pass") {
              msg.$message({
                message: "恭喜你，通过过测试",
                type: "success",
              });
            } else {
              msg.$message({
                message: "错误，未通过测试",
                type: "warning",
              });
            }
            this.testMsg = res.data.message;
          } else {
            msg.$message({
              message: "未响应",
            });
          }
        })
        .catch(function (error) {
          //错误处理 相当于error
          console.log(error);
          this.testMsg = res.data.error;
        });
    },
    //阅卷
    goOverPaper() {
      this.totalScore = 0;
      this.goOverChoice();
      this.goOverFill();
      this.goOverJudge();
      this.goOverProgram();
      this.goOverMulChoice();

    },
    //选择题阅卷
    goOverChoice() {
      for (let i = 0; i < this.choices.length; i++) {
        if (this.isPractice == true) {
          axios
            .post(`/records/choices/`, {
              practice_id: this.getPractice.id,
              student_id: this.getStudent.id,
              choice_id: this.choices[i].id,
              your_answer: this.answer[0][i],
            })
            .then((res) => {
              console.log(res); //处理成功的函数 相当于success
            })
            .catch(function (error) {
              console.log(error); //错误处理 相当于error
            });
        }
        //判断是否正确
        if (this.choices[i].right_answer == this.answer[0][i]) {
          this.totalScore += this.choices[i].score;
        }
      }
    },
        //选择题阅卷
    goOverMulChoice() {
      for (let i = 0; i < this.mulchoices.length; i++) {
        // if (this.isPractice == true) {
        //   axios
        //     .post(`/records/choices/`, {
        //       practice_id: this.getPractice.id,
        //       student_id: this.getStudent.id,
        //       choice_id: this.choices[i].id,
        //       your_answer: this.answer[0][i],
        //     })
        //     .then((res) => {
        //       console.log(res); //处理成功的函数 相当于success
        //     })
        //     .catch(function (error) {
        //       console.log(error); //错误处理 相当于error
        //     });
        // }
        //判断是否正确
        // if (this.mulchoices[i].right_answer == this.mulchoice_answer[i]) {
        //   this.totalScore += this.choices[i].score;
        // }

        var rightA=this.mulchoices[i].right_answer.sort().join('')
        var writeA=this.mulchoice_answer[i].sort().join('')
        if (rightA == writeA) {
          this.totalScore += this.mulchoices[i].score;
        }
        

        console.log('多选正确答案------------',this.mulchoices[i].right_answer.sort().join('') )
        console.log('多选选择答案2------------',this.mulchoice_answer[i].sort().join(''))
        console.log(this.totalScore)
        // [1,2,3].sort().toString()== [3,2,1].sort().toString()



      }
    },
    





    //填空题阅卷
    goOverFill() {
      for (let i = 0; i < this.fills.length; i++) {
        //如果是模拟练习，保存答题记录
        if (this.isPractice == true) {
          axios
            .post(`/records/fills/`, {
              practice_id: this.getPractice.id,
              student_id: this.getStudent.id,
              fill_id: this.fills[i].id,
              your_answer: this.answer[1][i],
            })
            .then((res) => {
              console.log(res); //处理成功的函数 相当于success
            })
            .catch(function (error) {
              console.log(error); //错误处理 相当于error
            });
        }
        //判断是否正确
        if (this.fills[i].right_answer == this.answer[1][i]) {
          this.totalScore += this.fills[i].score;
        }
      }
    },
    //判断题阅卷
    goOverJudge() {
      for (let i = 0; i < this.judges.length; i++) {
        //如果是模拟练习，保存答题记录
        if (this.isPractice == true) {
          axios
            .post(`/records/judges/`, {
              practice_id: this.getPractice.id,
              student_id: this.getStudent.id,
              judge_id: this.judges[i].id,
              your_answer: this.answer[2][i],
            })
            .then((res) => {
              console.log(res); //处理成功的函数 相当于success
            })
            .catch(function (error) {
              console.log(error); //错误处理 相当于error
            });
        }
        //判断是否正确
        if (this.judges[i].right_answer == this.answer[2][i]) {
          this.totalScore += this.judges[i].score;
        }
      }
    },
    //编程题阅卷
    // goOverProgram: async function () {
    goOverProgram () {
      for (let i = 0; i < this.programs.length; i++) {
        // let index = this.current - parseInt(this.programPos);
        // if (index >= 0 && this.answer[3][index] == null) {
        //   this.answer[3][index] = this.programs[index].answer_template;
        // }
        // let index = this.current - parseInt(this.programPos);
        // print(111)
        if ( this.answer[3][i] == this.programs[i].answer_template) {
            this.totalScore += this.programs[i].score;

        }
      }

      // for (let i = 0; i < this.programs.length; i++) {
      //   //判断是否正确
      //   await axios
      //     .post(`/check-program/`, {
      //       answer: this.answer[3][i] == undefined ? "" : this.answer[3][i],
      //       program: this.programs[i],
      //     })
      //     .then((res) => {
      //       //处理成功的函数 相当于success
      //       if (res && res.data.message == "pass") {
      //         this.totalScore += this.programs[i].score;
      //       }
      //       //如果是模拟练习，保存答题记录
      //       if (this.isPractice == true) {
      //         axios
      //           .post(`/records/programs/`, {
      //             practice_id: this.getPractice.id,
      //             student_id: this.getStudent.id,
      //             program_id: this.programs[i].id,
      //             your_answer: this.answer[3][i],
      //             cmd_msg: res.data.message,
      //           })
      //           .then((res) => {
      //             console.log(res); //处理成功的函数 相当于success
      //           })
      //           .catch(function (error) {
      //             console.log(error); //错误处理 相当于error
      //           });
      //       }
      //     })
      //     .catch(function (error) {
      //       //错误处理 相当于error
      //     });
      // }
    },

    getCompetence() {
      var _this = this;
      this.thisCancas = document.getElementById("canvasCamera");
      this.thisContext = this.thisCancas.getContext("2d");
      this.thisVideo = document.getElementById("videoCamera");
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象

      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }

      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象

      // 使用getUserMedia，因为它会覆盖现有的属性。

      // 这里，如果缺少getUserMedia属性，就添加它。

      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)

          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      var constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: "scaleX(-1)",
        },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject

          if ("srcObject" in _this.thisVideo) {
            _this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。

            _this.thisVideo.src = window.URL.createObjectURL(stream);
          }
          // _this.thisVideo.onloadedmetadata = function (e) {
          //   _this.thisVideo.play()
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /*
     *@function  绘制图片
     *****************************************/

    setImage() {
      var _this = this;
      // 点击，canvas画图
      _this.thisContext.drawImage(
        _this.thisVideo,
        0,
        0,
        _this.videoWidth,
        _this.videoHeight
      );

      // 获取图片base64链接

      var image = this.thisCancas.toDataURL("image/png");
      // console.log("image", image);
      // this.onSubmit(image);
      _this.imgSrc = image;
      // console.log('this.image',this.imgSrc)
      // console.log('this.value',this.value)
      this.$emit("refreshDataList", this.imgSrc);
    },

    onSubmit(e) {
      var param = {
        // word: e,
        word: e,
      };
      // console.log('param.word',param.word)
      this.axios
        .post("http://127.0.0.1:8000/face/a", param)
        .then((res) => {
          // this.pic = res.data;
          // console.log("res", res.data.name);
          this.global_name = res.data.name;
          console.log("this.global_name", this.global_name);
          // this.value = res.data;
          // console.log('this.value',this.value)
          // console.log(11111);
        })
        .catch((res) => {
          console.log(res);
          // console.log(res.data.res);
        });
    },

    getall() {
      var imgSrc = this.imgSrc;
      this.setImage();

      if (imgSrc != "") {
        console.log("imgSrc22", imgSrc);
        this.onSubmit(imgSrc);
      } else {
        console.log("imgSrc111", imgSrc);
      }
      // this.onSubmit(imgSrc);
    },

    //  get() {
    //     this.value =this.value+7;
    //     console.log(this.value);
    //   },

    // setTimer() {
    //   if (this.timer == null) {
    //     this.timer = setInterval(() => {
    //       // console.log("开始定时...每过一秒执行一次");
    //       this.setImage();
    //     }, 90000);
    //   }
    // },

    stopNavigator() {
      this.thisVideo.srcObject.getTracks()[0].stop();
    },

    //交卷
    handIn() {
      this.goOverPaper();
      const temp = this;
      clearTimeout(this.timer); //清除延迟执行
      this.timer = setTimeout(() => {
        //设置延迟执行
        if (this.isPractice == true) {
          this.$message({
            message: "你本次的测试成绩为：" + this.totalScore + "分",
            type: "success",
          });
          //跳转到模拟练习
          this.$router.push("/practice");
        } else {
          this.$message({
            message: "你本次的考试成绩为：" + this.totalScore + "分",
            type: "success",
          });
          // 考试成绩存库
          axios
            .post(`/grades/`, {
              exam_id: this.getExam.id,
              student_id: temp.getStudent.id,
              score: this.totalScore,
            })
            .then((res) => {
              //处理成功的函数 相当于success
              //跳转到我的成绩
              this.$router.push("/grade");
            })
            .catch(function (error) {
              //错误处理 相当于error
            });
        }
      }, 1000);
    },
  },
  mounted() {
    this.getCompetence(); // 打开视频
    // setInterval( this.setImage(), 60000);
    //  setInterval( this.setImage(), 30000);
  },

  beforeDestroy() {
    this.stopNavigator();
  },

  created() {
    this.getClazzInfo();
    this.getChoiceInfo();
    this.getFillInfo();
    this.getJudgeInfo();
    this.getProgramInfo();
    this.getMulChoiceInfo();

    // this.setTimer()
    // setInterval( this.setImage(), 30000);
  },
};
</script>

<style lang="scss" scoped>
#videoCamera {
  border-radius: 10%;
}

.camera_outer {
  /* position: relative; */
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
  /* overflow: hidden; */
  /* background-size: 100%; */
}

video,
canvas,
.tx_img {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

.btn_camera {
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 50px;
  text-align: center;
  color: #ffffff;
}

.bg_r_img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.img_bg_camera {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.img_btn_camera {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.el-header {
}

.el-aside {
  margin-left: 50px;
}

.el-main {
}

.el-footer {
}

.el-footer .el-row {
  margin-top: 10px;
}

#information {
  // margin-top: 20px;
}

.box-div {
  border-radius: 2px;
}

.box-card {
  width: 250px;
  border-radius: 2px;
}

.question-tag {
  text-align: left;
}

.el-tag {
  margin-top: 5px;
  margin-left: 10px;
  // width: 30px;
  height: 30px;
  align-items: center;
}

.bottom .el-tag {
  // width: 20px;
  // height: 20px;

  width: 50px;
  // height: 35px;
  //  text-align: left;
  // padding: 10px;
  // text-align: center;
  // vertical-align: bottom;
  //     height: 32px;
  // line-height: 30px;
  //     padding-top: 0;
  //     padding-bottom: 0;
}

#choice {
  float: left;
  width: auto;
  text-align: left;
}

#choice .el-radio-group {
  margin-left: 20px;
  text-align: left;
}

#choice .el-radio {
  margin-top: 40px;
}

#mulchoice {
  float: left;
  width: auto;
  text-align: left;
}

#mulchoice .el-checkbox-group {
  margin-left: 20px;
  text-align: left;
}

#mulchoice .el-checkbox {
  margin-top: 40px;
}

#fill {
  float: left;
  width: auto;
  text-align: left;
}

#fill .el-input {
  margin-top: 20px;
  margin-left: 30px;
}

#judge {
  float: left;
  width: auto;
  text-align: left;
}

#judge .el-radio {
  margin-top: 40px;
}

#program {
  float: left;
  // width: 1000px;
}
// .clearfix {
//   display: flex;

// }
// .bottomText{
//   display: flex;
//   // align-items: center;
//   // text-align:center

// }

div {
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
</style>
